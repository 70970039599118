import React, { useContext, useEffect } from 'react';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Typography, } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { UserContext } from '../context/UserContext';
import useSnackbar from '../hooks/useSnackbar';
import api from '../services/api';
import { NotificationsContext } from '../context/NotificationContext';
import BackButton from './BackButton';
import { Role } from './PrivateRoute';

interface HeaderProps {
    title?: string;
    showBack?: boolean;
    showNotifications?: boolean;
    avatarUrl?: string;
    username?: string;
}

const MerchantHeader: React.FC<HeaderProps> = ({
                                                   title,
                                                   showBack = true,
                                                   showNotifications = true,
                                                   avatarUrl,
                                                   username,
                                               }) => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const {notificationCount, setNotificationCount} = useContext(NotificationsContext);
    const {showSnackbar} = useSnackbar();

    useEffect(() => {
        const fetchNotificationCount = async () => {
            if (!user?.id || !showNotifications) {
                return;
            }

            try {
                const query = qs.stringify(
                    {
                        filters: {
                            user: {
                                id: {
                                    $eq: user.id,
                                },
                            },
                        },
                        pagination: {
                            pageSize: 0,
                        },
                    },
                    {
                        encodeValuesOnly: true,
                    }
                );

                const response = await api.get(`/notifications?${query}`);

                const total = response.data.meta.pagination.total;
                setNotificationCount(total);
            } catch (err) {
                console.error('Error fetching notification count:', err);
                showSnackbar('Error fetching notification count.', 'error');
            }
        };

        fetchNotificationCount();
    }, [user?.id, setNotificationCount, showSnackbar, showNotifications]);

    return (
        <AppBar position="static" color="inherit" elevation={0} sx={{pt: 2, height: '90px'}}>
            <Toolbar sx={{justifyContent: 'space-between'}}>
                {showBack && (
                    <BackButton/>
                )}
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {avatarUrl && (
                        <Avatar alt="User Avatar" src={avatarUrl} sx={{width: 48, height: 48, mr: 3}}/>
                    )}
                    {username ? (
                        <Typography
                            fontSize={15}
                            fontWeight={500}
                            marginBottom={0}
                            color="rgba(160, 157, 180, 1)"
                        >
                            {username}
                        </Typography>
                    ) : (
                        title && (
                            <Typography fontSize={20} fontWeight="bold" marginBottom={0}>
                                {title}
                            </Typography>
                        )
                    )}
                </Box>
                {showNotifications ? (
                    <IconButton
                        color="inherit"
                        sx={{border: '0.5px solid rgba(21, 21, 33, 0.2)'}}
                        onClick={() => navigate(user?.role === Role.Merchant ? '/merchant/notifications' : '/notifications')}
                    >
                        <Badge
                            badgeContent={
                                notificationCount
                            }
                            color="warning"
                        >
                            <NotificationsIcon sx={{color: 'rgba(57, 57, 67, 1)'}}/>
                        </Badge>
                    </IconButton>
                ) : (
                    <Box sx={{width: '86px'}}></Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default React.memo(MerchantHeader);
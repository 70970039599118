import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import api, { API_URL, StrapiRole } from '../../services/api';
import BasePageLayout from '../../components/BasePageLayout';
import { User } from '../../context/UserContext';
import { ImageOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Toss: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const fetchMerchants = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            let query: any;

            if (searchTerm.length >= 3) {
                query = {
                    filters: {
                        role: { $eq: StrapiRole.Merchant },
                        username: {
                            $containsi: searchTerm,
                        },
                        tokens: {
                            $gt: 0,
                        },
                    },
                    populate: ['avatar', 'tokens', 'statistics'],
                    pagination: {
                        page: 1,
                        pageSize: 5,
                    },
                };
            } else {
                query = {
                    filters: {
                        role: { $eq: StrapiRole.Merchant },
                    },
                    sort: ['statistics.totalSold:desc'],
                    populate: ['avatar', 'tokens', 'statistics'],
                    pagination: {
                        page: 1,
                        pageSize: 10,
                    },
                };
            }

            const response = await api.get('/users', {
                params: query,
            });

            setResults(response.data);
        } catch (err) {
            console.error('Error fetching merchants', err);
            setError('Failed to fetch merchants.');
        } finally {
            setLoading(false);
        }
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchMerchants();
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, fetchMerchants]);

    return (
        <BasePageLayout>
            <Typography fontSize={16} mb={0} mt={1}>Toss</Typography>
            <TextField
                fullWidth
                placeholder="Search by merchant"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                margin="normal"
            />
            {error && <Typography color="error">{error}</Typography>}
            {results.length > 0 ? results.map((merchant) => merchant.tokens?.map(token => (new Date(token.expirationDate) > new Date() && token.isActive && token.quantity > 0) && (
                <Box
                    key={token.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1.5}
                    p={2}
                    gap={2}
                    sx={{
                        bgcolor: 'rgba(245, 245, 245, 1)',
                        borderRadius: '12px'
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <Avatar
                            sx={{
                                bgcolor: 'rgba(57, 57, 67, 1)',
                                mr: 2,
                                border: merchant.avatar?.url ? '' : '1px solid black',
                                height: 40,
                                width: 40,
                            }}
                            src={merchant.avatar?.url ? `${API_URL}${merchant.avatar?.url}` : ''}
                        >
                            {!merchant.avatar?.url && <ImageOutlined sx={{color: 'white'}}/>}
                        </Avatar>
                        <Avatar
                            sx={{
                                bgcolor: 'rgba(57, 57, 67, 1)',
                                mr: 2,
                                border: token.imageUrl ? '' : '1px solid black',
                                height: 40,
                                width: 40,
                            }}
                            src={token.imageUrl || ''}
                        >
                            {!token.imageUrl && <ImageOutlined sx={{color: 'white'}}/>}
                        </Avatar>
                        <Stack>
                            <Typography
                                fontWeight={600}
                                sx={{
                                    color: 'rgba(26, 29, 37, 1)',
                                }}
                            >
                                {token.itemName}
                            </Typography>
                            <Typography
                                fontWeight={500}
                                fontSize={10}
                                sx={{
                                    color: 'rgba(21, 21, 33, 0.5)',
                                }}
                            >
                                {merchant.username}
                            </Typography>
                        </Stack>
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{height: 55, width: 55, minWidth: 55, borderRadius: '12px'}}
                        onClick={() => navigate('/proceed-to-payment', {state: {token, merchant}})}
                    >
                        <Typography fontSize={12} fontWeight={500} color='white' mb={0}>Toss</Typography>
                    </Button>
                </Box>
            ))) : (<></>)}
            {loading && <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                <CircularProgress/>
            </Box>}
        </BasePageLayout>
    );
};

export default Toss;
import React from 'react';
import { Box, Container } from '@mui/material';
import Header from './Header';
import FooterMenu from './FooterMenu';

interface BasePageLayoutProps {
    children: React.ReactNode;
    title?: string;
    showBack?: boolean;
    notificationCount?: number;
    showNotifications?: boolean;
    avatarUrl?: string;
    username?: string;
}

const BasePageLayout: React.FC<BasePageLayoutProps> = ({
                                                           children,
                                                           title,
                                                           showBack,
                                                           showNotifications = true,
                                                           avatarUrl,
                                                           username,
                                                       }) => {
    return (
        <Box sx={{minHeight: '100dvh', display: 'flex', flexDirection: 'column'}}>
            <Box sx={{position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000}}>
                <Header
                    title={title}
                    showBack={showBack}
                    showNotifications={showNotifications}
                    avatarUrl={avatarUrl}
                    username={username}
                />
            </Box>

            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto'
            }}>
                <Container sx={{
                    mt: '90px',
                    mb: '106px',
                }}>
                    {children}
                </Container>
            </Box>

            <Box sx={{position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1000}}>
                <FooterMenu/>
            </Box>
        </Box>
    );
};

export default BasePageLayout;
import React, { FC } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import { getDateTimeText } from '../../../services/utils';
import { Transaction } from './MerchantTransactions';

const MerchantTransactionItem: FC<{ transaction: Transaction }> = ({transaction}) => {
    const transactionDate = new Date(transaction.createdAt);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1.5}
            p={2}
            gap={2}
            sx={{
                bgcolor: 'rgba(245, 245, 245, 1)',
                borderRadius: '12px',
            }}
        >
            <Box display="flex">
                <Avatar
                    sx={{
                        bgcolor: 'rgba(57, 57, 67, 1)',
                        mr: 2,
                        border: transaction.token.imageUrl ? '' : '1px solid black',
                        height: 40,
                        width: 40,
                    }}
                    src={transaction.token.imageUrl || ''}
                >
                    {!transaction.token.imageUrl && <ImageOutlined sx={{color: 'white'}}/>}
                </Avatar>
                <Stack>
                    <Typography
                        fontWeight={600}
                        sx={{color: 'rgba(26, 29, 37, 1)'}}
                    >
                        {transaction.status}
                    </Typography>
                    <Typography
                        fontWeight={500}
                        fontSize={10}
                        sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                    >
                        {getDateTimeText(transactionDate)}
                    </Typography>
                </Stack>
            </Box>

            <Stack textAlign="end">
                <Typography
                    fontWeight={600}
                    sx={{color: 'rgba(26, 29, 37, 1)'}}
                >
                    {transaction.recipientPhoneNumber}
                </Typography>
                <Typography
                    fontWeight={500}
                    fontSize={10}
                    sx={{color: 'rgba(21, 21, 33, 0.5)'}}
                >
                    {transaction.token.itemName} ({transaction.quantity})
                </Typography>
            </Stack>
        </Box>
    );
};

export default MerchantTransactionItem;
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Box, Button, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, Tune as TuneIcon, Close } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import qs from 'qs';
import api, { jwtTokenKey } from '../../../services/api';
import { UserContext } from '../../../context/UserContext';
import BasePageLayout from '../../../components/BasePageLayout';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { UserTokenStatus } from '../my-tokens/MyTokens';
import { AvTimer, Block, Person } from '@mui/icons-material';
import UserChart from './UserChart';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import FilterDropdown from '../../merchant/home/FilterDropdown';
import { Token } from '../../merchant/tokens/TokenDetailsPage';
import { Transaction } from '../../merchant/transactions/MerchantTransactions';
import useSnackbar from '../../../hooks/useSnackbar';
import UserTransactionItem from '../transactions/UserTransactionItem';

const Home: React.FC = () => {
    const {user, avatarUrl, fetchUser} = useContext(UserContext);
    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();

    const [redeemedCount, setRedeemedCount] = useState<number>(0);
    const [spinningCount, setSpinningCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [selectedToken, setSelectedToken] = useState<string>('all');
    const [appliedTokenFilter, setAppliedTokenFilter] = useState<string>('all');
    const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const filterButtonRef = useRef<HTMLButtonElement>(null);

    const fetchUserTokens = useCallback(async () => {
        if (!user) return;

        try {
            const response = await api.get('/bought-tokens', {
                params: {
                    filters: {
                        $or: [
                            {tosserPhoneNumber: {$eq: user.phoneNumber}},
                            {recipientPhoneNumber: {$eq: user.phoneNumber}},
                        ],
                    },
                    populate: {
                        token: {
                            populate: ['merchant'],
                        },
                    }
                },
            });

            const fetchedTokensData = response.data.data.map((item: any) => {
                const tokenData = item.attributes.token.data;
                const tokenAttributes = tokenData.attributes;
                const merchantData = tokenAttributes.merchant.data;

                return {
                    id: tokenData.id,
                    ...tokenAttributes,
                    merchant: {
                        id: merchantData.id,
                        ...merchantData.attributes,
                    },
                };
            });

            const uniqueTokensMap = new Map();
            fetchedTokensData.forEach((token: Token) => {
                uniqueTokensMap.set(token.id, token);
            });
            const uniqueTokens = Array.from(uniqueTokensMap.values());

            setTokens(uniqueTokens);
        } catch (error) {
            console.error('Error fetching user tokens:', error);
            showSnackbar('Error fetching tokens', 'error');
        }
    }, [user, showSnackbar]);

    const fetchTransactions = useCallback(async (tokenFilter: string | null = null) => {
        if (!user) return;

        setLoadingTransactions(true);

        try {
            const userPhoneNumber = user.phoneNumber;

            const conditions: any[] = [
                {
                    $or: [
                        {tosserPhoneNumber: {$eq: userPhoneNumber}},
                        {recipientPhoneNumber: {$eq: userPhoneNumber}},
                    ],
                },
            ];

            if (tokenFilter && tokenFilter !== 'all') {
                console.log(tokenFilter);
                conditions.push({
                    token: {
                        itemName: {
                            $eq: tokenFilter.trim(),
                        },
                    },
                });
            }

            const query = qs.stringify(
                {
                    filters: conditions.length > 0 ? {$and: conditions} : {},
                    pagination: {
                        page: 1,
                        pageSize: 4,
                    },
                    sort: ['createdAt:desc'],
                    populate: {
                        token: {
                            populate: ['merchant'],
                        },
                    },
                },
                {
                    encodeValuesOnly: true,
                }
            );

            const response = await api.get(`/transactions?${query}`);

            const fetchedTransactions: Transaction[] = response.data.data.map((item: any) => {
                const transactionAttributes = item.attributes;
                const tokenData = transactionAttributes.token.data;
                const tokenAttributes = tokenData.attributes;
                const merchantData = tokenAttributes.merchant.data;

                const token: Token = {
                    id: tokenData.id,
                    ...tokenAttributes,
                    merchant: {
                        id: merchantData.id,
                        ...merchantData.attributes,
                    },
                };

                const transaction: Transaction = {
                    id: item.id,
                    token: token,
                    tosserPhoneNumber: transactionAttributes.tosserPhoneNumber,
                    recipientPhoneNumber: transactionAttributes.recipientPhoneNumber,
                    quantity: transactionAttributes.quantity,
                    status: transactionAttributes.status,
                    createdAt: new Date(transactionAttributes.createdAt),
                };

                return transaction;
            });

            setTransactions(fetchedTransactions);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            showSnackbar('Error fetching transactions', 'error');
        } finally {
            setLoadingTransactions(false);
        }
    }, [user, showSnackbar]);

    const fetchStatistics = useCallback(async () => {
        if (!user) return;

        setLoading(true);

        try {
            const userPhoneNumber = user.phoneNumber;

            const redeemedQuery = qs.stringify(
                {
                    filters: {
                        status: {$eq: UserTokenStatus.Redeemed},
                        tosserPhoneNumber: {$eq: userPhoneNumber},
                    },
                },
                {
                    encodeValuesOnly: true,
                }
            );

            const redeemedResponse = await api.get(`/transactions?${redeemedQuery}`);
            const redeemedTransactions = redeemedResponse.data.data;
            setRedeemedCount(redeemedTransactions.length);

            const spinningQuery = qs.stringify(
                {
                    filters: {
                        status: {$eq: UserTokenStatus.Spinning},
                        tosserPhoneNumber: {$eq: userPhoneNumber},
                    },
                },
                {
                    encodeValuesOnly: true,
                }
            );

            const spinningResponse = await api.get(`/transactions?${spinningQuery}`);
            const spinningTransactions = spinningResponse.data.data;

            const totalSpinningQuantity = spinningTransactions.reduce(
                (sum: number, item: any) => sum + (item.attributes.quantity || 0),
                0
            );
            setSpinningCount(totalSpinningQuantity);
        } catch (error) {
            console.error('Error fetching statistics:', error);
        } finally {
            setLoading(false);
        }
    }, [user]);

    useEffect(() => {
        const token = localStorage.getItem(jwtTokenKey);
        if (token && !user) {
            fetchUser();
        }
    }, [user, fetchUser]);

    useEffect(() => {
        fetchUserTokens();
        fetchTransactions(appliedTokenFilter);
        fetchStatistics();
    }, [fetchUserTokens, fetchTransactions, fetchStatistics, appliedTokenFilter]);

    const handleTokenFilterChange = (value: string) => {
        setSelectedToken(value);
    };

    const handleApplyFilter = () => {
        setAppliedTokenFilter(selectedToken);
        setFilterOpen(false);
    };

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (filterButtonRef.current && filterButtonRef.current.contains(event.target as Node)) {
            return;
        }
        setFilterOpen(false);
    };

    return (
        <BasePageLayout showBack={false} avatarUrl={avatarUrl || ''} username={user?.username}>
            <Box
                sx={{
                    textAlign: 'center',
                    mt: 2,
                    mb: 1,
                    display: 'flex',
                    gap: '10px',
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<TokenOutlinedIcon/>}
                    sx={{
                        height: 50,
                        flex: 1,
                        borderRadius: '12px',
                        backgroundColor: 'rgba(245, 245, 245, 1)',
                        color: 'rgba(57, 57, 67, 1)',
                        '&:hover': {
                            backgroundColor: 'rgba(230, 230, 230, 1)',
                        },
                    }}
                    onClick={() => navigate('/my-tokens')}
                >
                    My Tokens
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlineRoundedIcon/>}
                    sx={{height: 50, flex: 1, borderRadius: '12px'}}
                    onClick={() => navigate('/toss')}
                >
                    Toss
                </Button>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography fontSize={12} fontWeight={700} mb={0}>
                    Statistics
                </Typography>

                <Box display="flex" gap={1.5}>
                    {appliedTokenFilter !== 'all' && (
                        <Chip
                            label={appliedTokenFilter}
                            size="small"
                            deleteIcon={
                                <Close
                                    sx={{
                                        fontSize: '12px !important',
                                        color: 'white !important',
                                    }}
                                />
                            }
                            onDelete={() => {
                                setSelectedToken('all');
                                setAppliedTokenFilter('all');
                            }}
                            sx={{
                                fontSize: '10px',
                                fontWeight: '500',
                                borderRadius: '9px',
                                backgroundColor: 'rgba(57, 57, 67, 1)',
                                color: 'white !important',
                            }}
                        />
                    )}

                    <Button
                        ref={filterButtonRef}
                        sx={{
                            height: '25px',
                            width: '60px',
                            padding: 0,
                            backgroundColor: 'rgba(245, 245, 245, 1)',
                            color: 'rgba(57, 57, 67, 1)',
                            '&:hover': {
                                backgroundColor: 'rgba(57, 57, 67, 0.05)',
                            },
                        }}
                        disabled={!tokens || tokens.length === 0}
                        onClick={() => setFilterOpen((prev) => !prev)}
                        startIcon={<TuneIcon sx={{fontSize: '12px !important'}}/>}
                        endIcon={<KeyboardArrowDownIcon sx={{fontSize: '12px !important'}}/>}
                    />
                    <FilterDropdown
                        tokens={tokens}
                        selectedToken={selectedToken}
                        onChange={handleTokenFilterChange}
                        onApply={handleApplyFilter}
                        open={filterOpen}
                        onClickAway={handleClickAway}
                    />
                </Box>
            </Box>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                    <CircularProgress/>
                </Box>
            ) : (
                <>
                    <Box display="flex" gap={1} mt={1}>
                        <Box
                            height="88px"
                            width={1 / 2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                            bgcolor={'rgba(236, 249, 238, 1)'}
                            borderRadius="12px"
                        >
                            <UserChart
                                value={
                                    ((user?.statistics?.totalAccepted || 0) /
                                        ((user?.statistics?.totalAccepted || 0) +
                                            (user?.statistics?.totalDeclined || 0) +
                                            spinningCount)) *
                                    100
                                }
                                color="rgba(59, 186, 79, 1)"
                                icon={Person}
                            />
                            <Stack width="63px">
                                <Typography fontSize={18} fontWeight={600} mb={0} color="rgba(59, 186, 79, 1)">
                                    {user?.statistics?.totalAccepted || 0}
                                </Typography>
                                <Typography fontSize={12} fontWeight={500} color="rgba(26, 29, 37, 0.5)">
                                    Accepted
                                </Typography>
                            </Stack>
                        </Box>
                        <Box
                            height="88px"
                            width={1 / 2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                            bgcolor={'rgba(255, 166, 41, 0.1)'}
                            borderRadius="12px"
                        >
                            <UserChart
                                color="rgba(255, 166, 41, 1)"
                                value={
                                    (redeemedCount /
                                        ((user?.statistics?.totalAccepted || 0) +
                                            (user?.statistics?.totalDeclined || 0) +
                                            spinningCount)) *
                                    100
                                }
                                icon={ConfirmationNumberOutlinedIcon}
                            />
                            <Stack width="63px">
                                <Typography fontSize={18} fontWeight={600} mb={0} color="rgba(255, 166, 41, 1)">
                                    {redeemedCount}
                                </Typography>
                                <Typography fontSize={12} fontWeight={500} color="rgba(26, 29, 37, 0.5)">
                                    Redeemed
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                    <Box display="flex" gap={1} mt={1}>
                        <Box
                            height="88px"
                            width={1 / 2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                            bgcolor={'rgba(242, 72, 34, 0.1)'}
                            borderRadius="12px"
                        >
                            <UserChart
                                value={
                                    ((user?.statistics?.totalDeclined || 0) /
                                        ((user?.statistics?.totalAccepted || 0) +
                                            (user?.statistics?.totalDeclined || 0) +
                                            spinningCount)) *
                                    100
                                }
                                color="rgba(242, 72, 34, 1)"
                                icon={Block}
                            />
                            <Stack width="63px">
                                <Typography fontSize={18} fontWeight={600} mb={0} color="rgba(242, 72, 34, 1)">
                                    {user?.statistics?.totalDeclined || 0}
                                </Typography>
                                <Typography fontSize={12} fontWeight={500} color="rgba(26, 29, 37, 0.5)">
                                    Declined
                                </Typography>
                            </Stack>
                        </Box>
                        <Box
                            height="88px"
                            width={1 / 2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                            bgcolor={'rgba(66, 128, 239, 0.1)'}
                            borderRadius="12px"
                        >
                            <UserChart
                                value={
                                    (spinningCount /
                                        ((user?.statistics?.totalAccepted || 0) +
                                            (user?.statistics?.totalDeclined || 0) +
                                            spinningCount)) *
                                    100
                                }
                                color="rgba(66, 128, 239, 1)"
                                icon={AvTimer}
                            />
                            <Stack width="63px">
                                <Typography fontSize={18} fontWeight={600} mb={0} color="rgba(66, 128, 239, 1)">
                                    {spinningCount}
                                </Typography>
                                <Typography fontSize={12} fontWeight={500} color="rgba(26, 29, 37, 0.5)">
                                    Spinning
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </>
            )}

            <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
                <Typography fontSize={12} fontWeight={700}>
                    Recent Transactions
                </Typography>

                <RouterLink to="/transactions" style={{textDecoration: 'none'}}>
                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        color="rgba(66, 128, 239, 1)"
                        variant="body2"
                        mb={0}
                        height="fit-content"
                    >
                        See All Transactions
                    </Typography>
                </RouterLink>
            </Box>

            {loadingTransactions ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress/>
                </Box>
            ) : transactions.length > 0 ? (
                transactions.map((transaction) => (
                    <UserTransactionItem key={transaction.id} transaction={transaction} user={user!}/>
                ))
            ) : (
                <Typography>No transactions available.</Typography>
            )}
        </BasePageLayout>
    );
};

export default Home;
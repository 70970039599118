import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import api, { API_URL, jwtTokenKey, StrapiRole } from '../services/api';
import { useNavigate } from 'react-router-dom';
import useSnackbar from '../hooks/useSnackbar';
import { Role } from '../components/PrivateRoute';import { Token } from '../pages/merchant/tokens/TokenDetailsPage';

export interface User {
    id: number;
    username: string;
    email?: string;
    phoneNumber?: string;
    address?: string;
    supportPhoneNumber?: string;
    supportEmail?: string;
    avatar?: {
        id: number;
        url: string;
    };
    role: Role | StrapiRole;
    tokens?: Token[];
    statistics: {
        totalSold: number;
        totalBought: number;
        totalAccepted: number;
        totalDeclined: number;
    }
}

interface UserContextProps {
    user: User | null;
    avatarUrl: string | null;
    loading: boolean;
    fetchUser: () => Promise<void>;
    updateUser: (updatedUser: Partial<User> | null) => void;
}

export const UserContext = createContext<UserContextProps>({
    user: null,
    avatarUrl: null,
    loading: true,
    fetchUser: async () => {},
    updateUser: () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const navigateRef = useRef(navigate);
    const showSnackbarRef = useRef(showSnackbar);

    const fetchUser = useCallback(async () => {
        setLoading(true);
        try {
            const jwtToken = localStorage.getItem(jwtTokenKey);

            if (jwtToken) {
                const response = await api.get('/users/me', {
                    params: {
                        populate: ['avatar', 'role', 'statistics'],
                    },
                });
                const userData = response.data;
                userData.role = userData.role.name;

                setUser(userData);
                const avatarUrl = userData.avatar?.url ? `${API_URL}${userData.avatar.url}` : null;
                setAvatarUrl(avatarUrl);
            } else {
                setUser(null);
                setAvatarUrl(null);
            }
        } catch (error) {
            showSnackbarRef.current('Error fetching user data', 'error');
            console.error('Error fetching user data:', error);
            setUser(null);
            setAvatarUrl(null);
            localStorage.removeItem(jwtTokenKey);
            navigateRef.current('/login');
        } finally {
            setLoading(false);
        }
    }, []);

    const updateUser = (updatedUser: Partial<User> | null) => {
        if (updatedUser === null) {
            setUser(null);
            setAvatarUrl(null);
        } else {
            setUser((prevUser) => (prevUser ? { ...prevUser, ...updatedUser } : prevUser));

            if (updatedUser.avatar?.url) {
                setAvatarUrl(`${API_URL}${updatedUser.avatar.url}`);
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem(jwtTokenKey);
        if (token && !user) {
            fetchUser();
        } else {
            setLoading(false);
        }
    }, [fetchUser, user]);

    return (
        <UserContext.Provider value={{ user, avatarUrl, loading, fetchUser, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};